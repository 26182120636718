@import "var.conf";
@import "base.css";
@import "common.css";
@import "page2.css";

/*---------------------------------
bit Page CSS 2010.08
CSS 担当者名 Document 2010.08
Last Update 2010.00.00 担当者名
---------------------------------*/


/*
404
-------------------------------------*/

#Page404 {
    #Container {
        width: 100%;
        #Main #ContBox01 {
            width: 100%;
            .contSubBox {}
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
}


/*
トップページ
-------------------------------------*/

#Page.pageIndex {
    #Container {
        width: 100%;
        #GlobalNav2 {
            position: relative;
            z-index: 100;
            width: 100%;
            height: 67px;
            background-color: #fff;
            border-bottom: 1px solid #d8d8d8;
            ul {
                border-right: 1px solid #d8d8d8;
                font-size: 0;
                li {
                    display: inline-block;
                    height: 67px;
                    border-left: 1px solid #d8d8d8;
                    text-align: center;
                    width: 499px;
                    a {
                        display: block;
                        width: 499px;
                        font-size: 18px;
                        font-weight: bold;
                        height: 67px;
                        line-height: 67px;
                        text-decoration: none;
                        color: #333;
                        opacity: 1;
                    }
                    &:nth-child(1) {
                        background: url("../img/ico_index_arrowu2.png") no-repeat scroll center 49px;
                        &:hover {
                            border-bottom: 3px solid #c58756;
                        }
                        &:not(.active) a {
                            color: #bb6827;
                            &:hover {
                                background-color: #c58756;
                                color: #fff;
                            }
                        }
                        &.active {
                            background: none;
                            border-bottom: 3px solid #c58756;
                            &:hover {
                                background-color: #fff;
                                color: #333;
                            }
                        }
                    }
                    &:nth-child(2) {
                        background: url("../img/ico_index_arrowu.png") no-repeat scroll center 49px;
                        &:hover {
                            border-bottom: 3px solid #419dc4;
                        }
                        &:not(.active) a {
                            color: #0c8dc4;
                            &:hover {
                                background-color: #419dc4;
                                color: #fff;
                            }
                        }
                        &.active {
                            background: none;
                            border-bottom: 3px solid #419dc4;
                            &:hover {
                                background-color: #fff;
                                color: #333;
                            }
                        }
                    }
                    a {}
                }
            }
        }
        #Main {
            float: none;
            width: 100%;
            #ContBox01 {
                width: 100%;
                background-color: #f4f4f4;
                .contSubBox01 {
                    border-top: 1px solid #e9ebed;
                    border-bottom: 1px solid #e9ebed;
                    padding-top: 60px;
                    padding-bottom: 60px;
                    div.sangyo,
                    div.senpaku {
                        a {
                            display: inline-block;
                            margin-right: 20px;
                            width: 232px;
                            & div {
                                display: table-cell;
                                font-size: 20px;
                                height: 241px;
                                width: 235px;
                                padding-top: 165px;
                            }
                            span {
                                font-size: 22px;
                                font-weight: bold;
                                span {
                                    margin-bottom: 14px;
                                    font-size: 14px;
                                    color: #888;
                                    display: block;
                                }
                            }
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                    div.sangyo {
                        a {
                            &:nth-child(1) {
                                background: url("../img/index_img07.jpg") no-repeat scroll left top;
                            }
                            &:nth-child(2) {
                                background: url("../img/index_img08.jpg") no-repeat scroll left top;
                            }
                            &:nth-child(3) {
                                background: url("../img/index_img09.jpg") no-repeat scroll left top;
                            }
                            &:nth-child(4) {
                                margin-right: 0;
                                background: url("../img/index_img10.jpg") no-repeat scroll left top;
                            }
                        }
                    }
                    div.senpaku {
                        display: none;
                        width: 745px;
                        margin: 0 auto;
                        a {
                            &:nth-child(1) {
                                background: url("../img/index_img01.jpg") no-repeat scroll left top;
                            }
                            &:nth-child(2) {
                                background: url("../img/index_img02.jpg") no-repeat scroll left top;
                            }
                            &:nth-child(3) {
                                margin-right: 0;
                                background: url("../img/index_img03.jpg") no-repeat scroll left top;
                            }
                        }
                    }
                    div.seihin {
                        background: url(../img/ico_index_arrow.png) no-repeat 463px center;
                        margin-top: 41px;
                        height: 45px;
                        width: 490px;
                        margin: 0 auto;
                        border: 1px solid #c2dfaa;
                        line-height: 45px;
                        text-align: center;
                        a {
                            display: block;
                            color: #4fb000;
                            font-size: 15px;
                            &:hover {
                                background: #4fb000 url(../img/ico_index_arrowh.png) no-repeat 463px center;
                                color: #fff;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
            #ContBox02 {
                padding-top: 60px;
                padding-bottom: 60px;
                background: url("../img/index_bg01.jpg") repeat scroll left top;
                .contSubBox02 {
                    ul {
                        li {
                            width: 320px;
                            margin-right: 16px;
                            /*20px*/
                            height: 96px;
                            display: inline-block;
                            a {
                                display: block;
                                width: 320px;
                                height: 96px;
                                line-height: 96px;
                                font-size: 18px;
                                font-weight: bold;
                                color: #000000;
                                text-align: center;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                            &:hover {
                                opacity: 0.8;
                            }
                        }
                        li:nth-child(1) {
                            background: url(../img/index_img04.jpg) no-repeat left top;
                        }
                        li:nth-child(2) {
                            background: url(../img/index_img05.jpg) no-repeat left top;
                        }
                        li:nth-child(3) {
                            margin-right: 0;
                            background: url(../img/index_img06.jpg) no-repeat left top;
                        }
                    }
                }
            }
            #ContBox03 {
                overflow: hidden;
                padding-top: 43px;
                padding-bottom: 43px;
                border-top: 1px solid #e9ebed;
                .left {
                    float: left;
                    width: 235px;
                    height: 153px;
                    p:nth-child(1) {
                        padding-top: 73px;
                        background: url(../img/icon_note.png) no-repeat center 25px;
                        font-size: 20px;
                        font-weight: bold;
                        letter-spacing: 3px;
                        text-align: center;
                    }
                    p:nth-child(2) {
                        width: 75px;
                        margin: 0 auto;
                        padding-left: 7px;
                        background: url(../img/icon_arrow2.png) no-repeat left center;
                        margin-top: 23px;
                        font-size: 12px;
                        text-align: center;
                        a {
                            color: #888888;
                        }
                    }
                }
                .right {
                    float: left;
                    width: 765px;
                    height: 111px;
                    dl {
                        float: left;
                        width: 765px;
                        padding-left: 20px;
                        padding-top: 16px;
                        height: 51px;
                        dt {
                            float: left;
                            width: 127px;
                            padding-left: 20px;
                            padding-top: 3px;
                            font-family: 'Open Sans', sans-serif;
                            font-weight: 400;
                            font-size: 14px;
                            color: #888888;
                        }
                        dd.dispCat {
                            background-color: #96b455;
                            color: #fff;
                            float: left;
                            text-align: center;
                            width: 86px;
                            font-size: 11px;
                            height: 19px;
                            line-height: 19px;
                        }
                        dd.dispTtl {
                            float: left;
                            padding-left: 30px;
                            color: #49a03a;
                            padding-top: 3px;
                            font-size: 14px;
                            background: rgba(0, 0, 0, 0) url("../img/ico_index_arrow.png") no-repeat scroll 502px center;
                            a {
                                width: 502px;
                                display: block;
                                color: #333;
                                text-decoration: none;
                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    dl:nth-child(2) {
                        padding-top: 16px;
                        border-top: 1px solid #ededed;
                        border-bottom: 1px solid #ededed;
                    }
                    dl:hover {
                        background-color: #f4f4f4;
                    }
                }
            }
        }
    }
    #BxNote {
        position: relative;
    }
    #bxNote {
        z-index: 100;
        position: absolute;
        top: 335px;
        right: 0px;
        font-size: 60px;
        font-weight: bold;
        color: #fff;
        text-align: right;
        line-height: 69px;
        letter-spacing: 10px;
        text-shadow: 0 0 10px #404040, 0 0 10px #404040;
    }
    .mainImgTop {
        position: relative;
        z-index: 1;
        .bx-wrapper {
            position: relative;
            width: 100%;
            min-width: 1000px;
            margin-right: auto;
            margin-left: auto;
        }
        .bxslider {
            li {
                background-color: #F0F0F0;
                text-align: center;
                height: 518px;
                text-indent: -9999px;
                &:first-child {
                    background: url(../img/mainimg_index01.jpg) no-repeat center center;
                    background-size: cover;
                }
                &:nth-child(2) {
                    background: url(../img/mainimg_index02.jpg) no-repeat center center;
                    background-size: cover;
                }
                &:nth-child(3) {
                    background: url(../img/mainimg_index03.jpg) no-repeat center center;
                    background-size: cover;
                }
                /*img {
                    min-width: 100%;
                    min-height: 100%;
                    position: absolute;
                    width: auto;
                    height: auto;
                    left: 0;
                    top: 50%;
                    // height:518px !important;
                }*/
            }
        }
        .bx-viewport {
            height: 518px !important;
        }
        .bx-controls {
            background-color: #151515;
            height: 28px;
        }
        .bx-pager {
            bottom: 10px;
            height: 8px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            width: 66px;
        }
        .bx-pager-item {
            display: inline-block;
        }
        .bx-pager-item a {
            background-color: #4fb000;
            border-radius: 4px;
            display: block;
            height: 9px;
            text-indent: -9999px;
            width: 9px;
            margin-left: 10px;
        }
        a.bx-pager-link.active {
            background-color: #888888;
        }
        .bx-controls-direction a.bx-prev {
            background: rgba(0, 0, 0, 0) url("../img/00_ico_arrow_prev.png") no-repeat scroll 0 0;
            left: 0;
        }
        .bx-controls-direction a.bx-next {
            background: rgba(0, 0, 0, 0) url("../img/00_ico_arrow_next.png") no-repeat scroll 0 0;
            right: 0;
        }
        .bx-controls-direction a {
            height: 45px;
            margin-top: -16px;
            outline: 0 none;
            position: absolute;
            text-indent: -9999px;
            top: 45%;
            width: 38px;
            z-index: 9999;
        }
    }
    #Footer {
        margin-top: 0;
    }
}


/*
製品紹介
-------------------------------------*/

#PageProducts.pageIndex {
    #Container {
        width: 100%;
        #Main {
            width: 1000px;
            margin: 0 auto;
            float: none;
            #ContBox01 {
                .contSubBox01,
                .contSubBox02 {
                    overflow: hidden;
                    h3 {
                        font-size: 30px;
                        height: 68px;
                        line-height: 68px;
                        border-bottom: 3px solid #419dc4;
                        margin-top: 0;
                        margin-bottom: 40px;
                    }
                    div {
                        float: left;
                        width: 480px;
                        margin-right: 40px;
                        margin-bottom: 40px;
                        figure {
                            background-color: #f5f5f5;
                            text-align: center;
                        }
                        h4 {
                            margin-top: 15px;
                            margin-bottom: 0px;
                            font-size: 22px;
                            font-weight: bold;
                            span {
                                display: block;
                                margin-bottom: 0;
                                font-size: 14px;
                                color: #888888;
                            }
                        }
                        div:nth-child(2) {
                            float: left;
                            width: 320px;
                            margin-bottom: 0;
                        }
                        div:nth-child(3) {
                            float: left;
                            width: 120px;
                            height: 45px;
                            line-height: 45px;
                            border: 1px solid #c6e1ed;
                            text-align: center;
                            margin-top: 20px;
                            margin-bottom: 0;
                            a {
                                background: rgba(0, 0, 0, 0) url("../img/product_arrow01.png") no-repeat scroll 93px center;
                                display: block;
                                width: 120px;
                                height: 43px;
                                font-size: 15px;
                                color: #0c8dc4;
                                &:hover {
                                    text-decoration: none;
                                    width: 120px;
                                    height: 44px;
                                    background-color: #419dc4;
                                    background: #419dc4 url("../img/product_arrow01h.png") no-repeat scroll 93px center;
                                    color: #fff;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    div:nth-child(odd) {
                        margin-right: 0;
                    }
                    div:last-child,
                    div:nth-last-of-type(2) {
                        margin-bottom: 0;
                    }
                }
                .contSubBox01 {
                    h3 {
                        border-bottom: 3px solid #c58756;
                    }
                    div {
                        div:nth-child(3) {
                            border: 1px solid #eddbcc;
                            a {
                                background: rgba(0, 0, 0, 0) url("../img/product_arrow02.png") no-repeat scroll 93px center;
                                color: #bb6827;
                                &:hover {
                                    background-color: #c58756;
                                    opacity: 1;
                                    background: #c58756 url("../img/product_arrow02h.png") no-repeat scroll 93px center;
                                }
                            }
                        }
                    }
                }
                .contSubBox02 {
                    h3 {
                        margin-top: 98px;
                    }
                }
                .contSubBox03 {
                    div {
                        text-align: center;
                        margin-top: 100px;
                        margin-bottom: 133px;
                        a {
                            background: rgba(0, 0, 0, 0) url("../img/product_arrow03.png") no-repeat scroll 952px center;
                            height: 105px;
                            line-height: 105px;
                            display: block;
                            border: 1px solid #cae7b2;
                            font-size: 22px;
                            color: #4fb000;
                            &:hover {
                                background-color: #4fb000;
                                opacity: 1;
                                color: #fff;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
}


/*
製品紹介 共通定義
-------------------------------------*/

#PageProducts.pageDiesel,
#PageProducts.pageInertgas,
#PageProducts.pageBoiler,
#PageProducts.pagePaint,
#PageProducts.pageCombustion,
#PageProducts.pageExhaustgas,
#PageProducts.pageParticular,
#PageProducts.pageFan {
    #Container {
        #Main {
            h3 {
                padding-top: 14px;
                height: 89px;
                line-height: 20px;
                letter-spacing: 3px;
                span {
                    letter-spacing: 1px;
                }
            }
            div {
                padding-left: 270px;
                p {
                    margin-bottom: 13px;
                    font-size: 16px;
                    line-height: 27px;
                }
                p:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}


/*
ディーゼル機関用補助ブロワ
-------------------------------------*/

#PageProducts.pageDiesel {
    #Main {
        div {
            background: url(../img/diesel_img02.jpg) no-repeat left top;
            background-size: 240px 148px;
        }
    }
}


/*
イナートガス装置用ブロワ
-------------------------------------*/

#PageProducts.pageInertgas {
    #Container {
        margin-bottom: 118px;
        #Main {
            div {
                background: url(../img/inato_img02.jpg) no-repeat left top;
                background-size: 240px 148px;
            }
        }
    }
}


/*
ボイラー用強圧ファン
-------------------------------------*/

#PageProducts.pageBoiler {
    #Container {
        margin-bottom: 138px;
        #Main {
            div {
                background: url(../img/boiler_img02.jpg) no-repeat left top;
                background-size: 240px 148px;
            }
        }
    }
}


/*
燃焼空気ファン
-------------------------------------*/

#PageProducts.pageCombustion {
    #Container {
        margin-bottom: 96px;
        #Main {
            div {
                background: url(../img/nensyou_img02.jpg) no-repeat left top;
                background-size: 240px 148px;
            }
        }
    }
}


/*
排ガス誘引ファン
-------------------------------------*/

#PageProducts.pageExhaustgas {
    #Container {
        margin-bottom: 166px;
        #Main {
            div {
                background: url(../img/haigus_img02.jpg) no-repeat left top;
                background-size: 240px 148px;
            }
        }
    }
}


/*
特殊ファン
-------------------------------------*/

#PageProducts.pageParticular {
    #Container {
        margin-bottom: 170px;
        #Main {
            div {
                background: url(../img/tokusyu_img02.jpg) no-repeat left top;
                background-size: 240px 148px;
            }
        }
    }
}


/*
塗装換気ファン
-------------------------------------*/

#PageProducts.pagePaint {
    #Container {
        margin-bottom: 170px;
        #Main {
            div {
                background: url(../img/tosou_img02.jpg) no-repeat left top;
                background-size: 240px 296px;
                height: 296px;
            }
        }
    }
}


/*
ファンタイプ別
-------------------------------------*/

#PageProducts.pageFan {
    #Container {
        margin-bottom: 158px;
        width: 100%;
        #Main {
            .contSubBox01 {
                padding-bottom: 80px;
                &>div {
                    overflow: hidden;
                }
            }
            .contSubBox02 {
                padding-bottom: 100px;
                div.clear+div {
                    p:first-child {
                        margin-top: 23px;
                    }
                }
            }
            h3 {
                height: 60px;
                &:first-child {}
            }
            h4 {
                &:first-child {
                    margin-top: 0;
                }
            }
            div {
                padding-left: 0;
                &.clear {
                    overflow: hidden;
                }
                &.left {
                    float: left;
                    width: 390px;
                }
                &.right {
                    float: right;
                    width: 280px;
                }
            }
            p.note {
                margin-top: 9px;
                font-size: 14px;
                color: #888888;
                text-align: right;
            }
        }
    }
}


/*
設計と開発 製造工程 p 共通定義
-------------------------------------*/

#PageDevelopment.pageIndex #Main p,
#PageProcess.pageIndex #Main p,
#PageMaintenance.pageIndex #Main p {
    font-size: 16px;
    line-height: 28px;
    margin-top: 14px;
}


/*
設計と開発 製造工程 h3 共通定義
-------------------------------------*/

#PageDevelopment.pageIndex #Main h3,
#PageProcess.pageIndex #Main h3,
#PageMaintenance.pageIndex #Main h3 {
    padding-bottom: 16px;
}


/*
ファンタイプ別 製造工程 h4 共通定義
-------------------------------------*/

#PageProducts.pageFan #Main h4,
#PageProcess.pageIndex #Main h4 {
    font-size: 22px;
    font-weight: bold;
    color: #009944;
    margin-top: 60px;
    padding-left: 20px;
    border: 1px solid #ccedda;
    background-color: #f2fef7;
    height: 60px;
    line-height: 60px;
}


/*
設計と開発
-------------------------------------*/

#PageDevelopment.pageIndex {
    #Container {
        width: 100%;
        margin-bottom: 125px;
        #Main {
            float: none;
            width: 100%;
            #ContBox01 {
                width: 100%;
            }
            h4 {
                font-size: 20px;
                font-weight: bold;
                color: #009944;
                line-height: 32px;
                margin-bottom: 22px;
            }
            .contSubBox01 {
                background: url(../img/develop_img01.jpg) no-repeat left top;
                background-size: 500px 309px;
                height: 309px;
                h4,
                p {
                    width: 470px;
                    margin-left: 530px;
                }
                p {
                    font-weight: bold;
                }
            }
            .contSubBox02 {
                margin-top: 46px;
            }
        }
    }
}


/*
製造工程
-------------------------------------*/

#PageProcess.pageIndex {
    #Container {
        width: 100%;
        margin-bottom: 150px;
        #Main {
            float: none;
            width: 100%;
            #ContBox01 {
                width: 100%;
            }
            p {
                width: 473px;
                margin-right: 30px;
            }
            p:first-child {
                margin-top: 0;
            }
            div {
                padding: 26px 0 60px 30px;
                margin-left: 30px;
                border-left: 7px solid #009944;
            }
            h4 {
                margin-top: 0;
                margin-bottom: 0;
            }
            .contSubBox01 div,
            .contSubBox02 div,
            .contSubBox03 div,
            .contSubBox04 div {
                height: 350px;
            }
            .contSubBox01 div {
                background: url(../img/koutei_img01.jpg) no-repeat right 26px;
                background-size: 430px 266px;
                height: 350px;
            }
            .contSubBox02 div {
                background: url(../img/koutei_img02.jpg) no-repeat right 26px;
                background-size: 430px 266px;
            }
            .contSubBox03 div {
                background: url(../img/koutei_img03.jpg) no-repeat right 26px;
                background-size: 430px 266px;
            }
            .contSubBox04 div {
                background: url(../img/koutei_img04.jpg) no-repeat right 26px;
                background-size: 430px 266px;
                height: 293px;
                border: none;
            }
        }
    }
}


/*
メンテナンス
-------------------------------------*/

#PageMaintenance.pageIndex {
    #Container {
        width: 100%;
        margin-bottom: 240px;
        #Main {
            float: none;
            width: 100%;
        }
        .contSubBox01 {
            height: 370px;
            background: url(../img/maintenance_img01.jpg) no-repeat left 132px;
            background-size: 1000px 240px;
            p {
                margin-top: 0;
            }
            ul {
                margin-top: 8px;
                li {
                    display: table-cell;
                    font-size: 11px;
                    height: 58px;
                    vertical-align: middle;
                    white-space: nowrap;
                    &.tel {
                        width: 200px;
                        span:nth-child(1) {
                            padding-left: 34px;
                            background: url("../img/ico_phone.png") no-repeat scroll left center;
                            background-size: 20px 20px;
                            font-size: 26px;
                            font-family: 'Open Sans', sans-serif;
                            font-weight: 700;
                            letter-spacing: 1px;
                        }
                        span:nth-child(2) {
                            display: block;
                            padding-top: 5px;
                            color: #888;
                            font-size: 11px;
                            text-align: center;
                            letter-spacing: 1px;
                        }
                    }
                    &:nth-child(2) a {
                        margin-left: 36px;
                        width: 180px;
                        height: 33px;
                        line-height: 33px;
                        text-align: center;
                        display: block;
                        background-color: #01a54f;
                        color: #fff;
                        font-size: 13px;
                        text-decoration: none;
                        line-height: 33px;
                    }
                }
            }
        }
        .contSubBox02 {
            height: 577px;
            margin-top: 100px;
            h3 {
                border-bottom: 3px solid #c58756;
            }
            ul {
                margin-top: 40px;
                li {
                    font-size: 20px;
                    font-weight: bold;
                    display: inline-block;
                    width: 300px;
                    height: 405px;
                    margin-right: 46px;
                    &:nth-child(1) {
                        background: url(../img/maintenance_img03.jpg) no-repeat left 33px;
                        background-size: 300px 372px;
                    }
                    &:nth-child(2) {
                        background: url(../img/maintenance_img04.jpg) no-repeat left 33px;
                        background-size: 300px 372px;
                    }
                    &:nth-child(3) {
                        margin-right: 0;
                        background: url(../img/maintenance_img05.jpg) no-repeat left 33px;
                        background-size: 300px 186px;
                    }
                }
            }
        }
        .contSubBox03 {
            h3 {
                border-bottom: 3px solid #419dc4;
            }
            height: 438px;
            margin-top: 100px;
            background: url(../img/maintenance_img02.jpg) no-repeat left 194px;
            background-size: 1000px 240px;
        }
    }
}


/*
お問い合わせ
-------------------------------------*/

#PageContact.pageIndex {
    #Container {
        width: 100%;
        #Main #ContBox01 {
            width: 100%;
            .contSubBox {}
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
}


/*
お問い合わせ
-------------------------------------*/

#PageContact.pageConfirm {
    #Container {
        width: 100%;
        #Main #ContBox01 {
            width: 100%;
            .contSubBox {}
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
}


/*
お問い合わせ
-------------------------------------*/

#PageContact.pageThanks {
    #Container {
        width: 100%;
        #Main #ContBox01 {
            width: 100%;
            .contSubBox {}
            #ContSubBox01 {}
            #ContSubBox02 {}
        }
    }
}
