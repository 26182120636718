@charset "UTF-8";
@import url(base.css);
@import url(common.css);
@import url(page2.css);
/*---------------------------------
bit Page CSS 2010.08
CSS 担当者名 Document 2010.08
Last Update 2010.00.00 担当者名
---------------------------------*/
/*
404
-------------------------------------*/
#Page404 #Container {
  width: 100%; }
  #Page404 #Container #Main #ContBox01 {
    width: 100%; }

/*
トップページ
-------------------------------------*/
#Page.pageIndex #Container {
  width: 100%; }
  #Page.pageIndex #Container #GlobalNav2 {
    position: relative;
    z-index: 100;
    width: 100%;
    height: 67px;
    background-color: #fff;
    border-bottom: 1px solid #d8d8d8; }
    #Page.pageIndex #Container #GlobalNav2 ul {
      border-right: 1px solid #d8d8d8;
      font-size: 0; }
      #Page.pageIndex #Container #GlobalNav2 ul li {
        display: inline-block;
        height: 67px;
        border-left: 1px solid #d8d8d8;
        text-align: center;
        width: 499px; }
        #Page.pageIndex #Container #GlobalNav2 ul li a {
          display: block;
          width: 499px;
          font-size: 18px;
          font-weight: bold;
          height: 67px;
          line-height: 67px;
          text-decoration: none;
          color: #333;
          opacity: 1; }
        #Page.pageIndex #Container #GlobalNav2 ul li:nth-child(1) {
          background: url("../img/ico_index_arrowu2.png") no-repeat scroll center 49px; }
          #Page.pageIndex #Container #GlobalNav2 ul li:nth-child(1):hover {
            border-bottom: 3px solid #c58756; }
          #Page.pageIndex #Container #GlobalNav2 ul li:nth-child(1):not(.active) a {
            color: #bb6827; }
            #Page.pageIndex #Container #GlobalNav2 ul li:nth-child(1):not(.active) a:hover {
              background-color: #c58756;
              color: #fff; }
          #Page.pageIndex #Container #GlobalNav2 ul li:nth-child(1).active {
            background: none;
            border-bottom: 3px solid #c58756; }
            #Page.pageIndex #Container #GlobalNav2 ul li:nth-child(1).active:hover {
              background-color: #fff;
              color: #333; }
        #Page.pageIndex #Container #GlobalNav2 ul li:nth-child(2) {
          background: url("../img/ico_index_arrowu.png") no-repeat scroll center 49px; }
          #Page.pageIndex #Container #GlobalNav2 ul li:nth-child(2):hover {
            border-bottom: 3px solid #419dc4; }
          #Page.pageIndex #Container #GlobalNav2 ul li:nth-child(2):not(.active) a {
            color: #0c8dc4; }
            #Page.pageIndex #Container #GlobalNav2 ul li:nth-child(2):not(.active) a:hover {
              background-color: #419dc4;
              color: #fff; }
          #Page.pageIndex #Container #GlobalNav2 ul li:nth-child(2).active {
            background: none;
            border-bottom: 3px solid #419dc4; }
            #Page.pageIndex #Container #GlobalNav2 ul li:nth-child(2).active:hover {
              background-color: #fff;
              color: #333; }
  #Page.pageIndex #Container #Main {
    float: none;
    width: 100%; }
    #Page.pageIndex #Container #Main #ContBox01 {
      width: 100%;
      background-color: #f4f4f4; }
      #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 {
        border-top: 1px solid #e9ebed;
        border-bottom: 1px solid #e9ebed;
        padding-top: 60px;
        padding-bottom: 60px; }
        #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.sangyo a,
        #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.senpaku a {
          display: inline-block;
          margin-right: 20px;
          width: 232px; }
          #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.sangyo a div,
          #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.senpaku a div {
            display: table-cell;
            font-size: 20px;
            height: 241px;
            width: 235px;
            padding-top: 165px; }
          #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.sangyo a span,
          #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.senpaku a span {
            font-size: 22px;
            font-weight: bold; }
            #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.sangyo a span span,
            #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.senpaku a span span {
              margin-bottom: 14px;
              font-size: 14px;
              color: #888;
              display: block; }
          #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.sangyo a:hover,
          #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.senpaku a:hover {
            text-decoration: none; }
        #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.sangyo a:nth-child(1) {
          background: url("../img/index_img07.jpg") no-repeat scroll left top; }
        #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.sangyo a:nth-child(2) {
          background: url("../img/index_img08.jpg") no-repeat scroll left top; }
        #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.sangyo a:nth-child(3) {
          background: url("../img/index_img09.jpg") no-repeat scroll left top; }
        #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.sangyo a:nth-child(4) {
          margin-right: 0;
          background: url("../img/index_img10.jpg") no-repeat scroll left top; }
        #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.senpaku {
          display: none;
          width: 745px;
          margin: 0 auto; }
          #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.senpaku a:nth-child(1) {
            background: url("../img/index_img01.jpg") no-repeat scroll left top; }
          #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.senpaku a:nth-child(2) {
            background: url("../img/index_img02.jpg") no-repeat scroll left top; }
          #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.senpaku a:nth-child(3) {
            margin-right: 0;
            background: url("../img/index_img03.jpg") no-repeat scroll left top; }
        #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.seihin {
          background: url(../img/ico_index_arrow.png) no-repeat 463px center;
          margin-top: 41px;
          height: 45px;
          width: 490px;
          margin: 0 auto;
          border: 1px solid #c2dfaa;
          line-height: 45px;
          text-align: center; }
          #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.seihin a {
            display: block;
            color: #4fb000;
            font-size: 15px; }
            #Page.pageIndex #Container #Main #ContBox01 .contSubBox01 div.seihin a:hover {
              background: #4fb000 url(../img/ico_index_arrowh.png) no-repeat 463px center;
              color: #fff;
              text-decoration: none; }
    #Page.pageIndex #Container #Main #ContBox02 {
      padding-top: 60px;
      padding-bottom: 60px;
      background: url("../img/index_bg01.jpg") repeat scroll left top; }
      #Page.pageIndex #Container #Main #ContBox02 .contSubBox02 ul li {
        width: 320px;
        margin-right: 16px;
        /*20px*/
        height: 96px;
        display: inline-block; }
        #Page.pageIndex #Container #Main #ContBox02 .contSubBox02 ul li a {
          display: block;
          width: 320px;
          height: 96px;
          line-height: 96px;
          font-size: 18px;
          font-weight: bold;
          color: #000000;
          text-align: center; }
          #Page.pageIndex #Container #Main #ContBox02 .contSubBox02 ul li a:hover {
            text-decoration: none; }
        #Page.pageIndex #Container #Main #ContBox02 .contSubBox02 ul li:hover {
          opacity: 0.8; }
      #Page.pageIndex #Container #Main #ContBox02 .contSubBox02 ul li:nth-child(1) {
        background: url(../img/index_img04.jpg) no-repeat left top; }
      #Page.pageIndex #Container #Main #ContBox02 .contSubBox02 ul li:nth-child(2) {
        background: url(../img/index_img05.jpg) no-repeat left top; }
      #Page.pageIndex #Container #Main #ContBox02 .contSubBox02 ul li:nth-child(3) {
        margin-right: 0;
        background: url(../img/index_img06.jpg) no-repeat left top; }
    #Page.pageIndex #Container #Main #ContBox03 {
      overflow: hidden;
      padding-top: 43px;
      padding-bottom: 43px;
      border-top: 1px solid #e9ebed; }
      #Page.pageIndex #Container #Main #ContBox03 .left {
        float: left;
        width: 235px;
        height: 153px; }
        #Page.pageIndex #Container #Main #ContBox03 .left p:nth-child(1) {
          padding-top: 73px;
          background: url(../img/icon_note.png) no-repeat center 25px;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 3px;
          text-align: center; }
        #Page.pageIndex #Container #Main #ContBox03 .left p:nth-child(2) {
          width: 75px;
          margin: 0 auto;
          padding-left: 7px;
          background: url(../img/icon_arrow2.png) no-repeat left center;
          margin-top: 23px;
          font-size: 12px;
          text-align: center; }
          #Page.pageIndex #Container #Main #ContBox03 .left p:nth-child(2) a {
            color: #888888; }
      #Page.pageIndex #Container #Main #ContBox03 .right {
        float: left;
        width: 765px;
        height: 111px; }
        #Page.pageIndex #Container #Main #ContBox03 .right dl {
          float: left;
          width: 765px;
          padding-left: 20px;
          padding-top: 16px;
          height: 51px; }
          #Page.pageIndex #Container #Main #ContBox03 .right dl dt {
            float: left;
            width: 127px;
            padding-left: 20px;
            padding-top: 3px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #888888; }
          #Page.pageIndex #Container #Main #ContBox03 .right dl dd.dispCat {
            background-color: #96b455;
            color: #fff;
            float: left;
            text-align: center;
            width: 86px;
            font-size: 11px;
            height: 19px;
            line-height: 19px; }
          #Page.pageIndex #Container #Main #ContBox03 .right dl dd.dispTtl {
            float: left;
            padding-left: 30px;
            color: #49a03a;
            padding-top: 3px;
            font-size: 14px;
            background: transparent url("../img/ico_index_arrow.png") no-repeat scroll 502px center; }
            #Page.pageIndex #Container #Main #ContBox03 .right dl dd.dispTtl a {
              width: 502px;
              display: block;
              color: #333;
              text-decoration: none; }
              #Page.pageIndex #Container #Main #ContBox03 .right dl dd.dispTtl a:hover {
                opacity: 1; }
        #Page.pageIndex #Container #Main #ContBox03 .right dl:nth-child(2) {
          padding-top: 16px;
          border-top: 1px solid #ededed;
          border-bottom: 1px solid #ededed; }
        #Page.pageIndex #Container #Main #ContBox03 .right dl:hover {
          background-color: #f4f4f4; }

#Page.pageIndex #BxNote {
  position: relative; }

#Page.pageIndex #bxNote {
  z-index: 100;
  position: absolute;
  top: 335px;
  right: 0px;
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  text-align: right;
  line-height: 69px;
  letter-spacing: 10px;
  text-shadow: 0 0 10px #404040, 0 0 10px #404040; }

#Page.pageIndex .mainImgTop {
  position: relative;
  z-index: 1; }
  #Page.pageIndex .mainImgTop .bx-wrapper {
    position: relative;
    width: 100%;
    min-width: 1000px;
    margin-right: auto;
    margin-left: auto; }
  #Page.pageIndex .mainImgTop .bxslider li {
    background-color: #F0F0F0;
    text-align: center;
    height: 518px;
    text-indent: -9999px;
    /*img {
                    min-width: 100%;
                    min-height: 100%;
                    position: absolute;
                    width: auto;
                    height: auto;
                    left: 0;
                    top: 50%;
                    // height:518px !important;
                }*/ }
    #Page.pageIndex .mainImgTop .bxslider li:first-child {
      background: url(../img/mainimg_index01.jpg) no-repeat center center;
      background-size: cover; }
    #Page.pageIndex .mainImgTop .bxslider li:nth-child(2) {
      background: url(../img/mainimg_index02.jpg) no-repeat center center;
      background-size: cover; }
    #Page.pageIndex .mainImgTop .bxslider li:nth-child(3) {
      background: url(../img/mainimg_index03.jpg) no-repeat center center;
      background-size: cover; }
  #Page.pageIndex .mainImgTop .bx-viewport {
    height: 518px !important; }
  #Page.pageIndex .mainImgTop .bx-controls {
    background-color: #151515;
    height: 28px; }
  #Page.pageIndex .mainImgTop .bx-pager {
    bottom: 10px;
    height: 8px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 66px; }
  #Page.pageIndex .mainImgTop .bx-pager-item {
    display: inline-block; }
  #Page.pageIndex .mainImgTop .bx-pager-item a {
    background-color: #4fb000;
    border-radius: 4px;
    display: block;
    height: 9px;
    text-indent: -9999px;
    width: 9px;
    margin-left: 10px; }
  #Page.pageIndex .mainImgTop a.bx-pager-link.active {
    background-color: #888888; }
  #Page.pageIndex .mainImgTop .bx-controls-direction a.bx-prev {
    background: transparent url("../img/00_ico_arrow_prev.png") no-repeat scroll 0 0;
    left: 0; }
  #Page.pageIndex .mainImgTop .bx-controls-direction a.bx-next {
    background: transparent url("../img/00_ico_arrow_next.png") no-repeat scroll 0 0;
    right: 0; }
  #Page.pageIndex .mainImgTop .bx-controls-direction a {
    height: 45px;
    margin-top: -16px;
    outline: 0 none;
    position: absolute;
    text-indent: -9999px;
    top: 45%;
    width: 38px;
    z-index: 9999; }

#Page.pageIndex #Footer {
  margin-top: 0; }

/*
製品紹介
-------------------------------------*/
#PageProducts.pageIndex #Container {
  width: 100%; }
  #PageProducts.pageIndex #Container #Main {
    width: 1000px;
    margin: 0 auto;
    float: none; }
    #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01,
    #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 {
      overflow: hidden; }
      #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 h3,
      #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 h3 {
        font-size: 30px;
        height: 68px;
        line-height: 68px;
        border-bottom: 3px solid #419dc4;
        margin-top: 0;
        margin-bottom: 40px; }
      #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div,
      #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 div {
        float: left;
        width: 480px;
        margin-right: 40px;
        margin-bottom: 40px; }
        #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div figure,
        #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 div figure {
          background-color: #f5f5f5;
          text-align: center; }
        #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div h4,
        #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 div h4 {
          margin-top: 15px;
          margin-bottom: 0px;
          font-size: 22px;
          font-weight: bold; }
          #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div h4 span,
          #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 div h4 span {
            display: block;
            margin-bottom: 0;
            font-size: 14px;
            color: #888888; }
        #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div div:nth-child(2),
        #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 div div:nth-child(2) {
          float: left;
          width: 320px;
          margin-bottom: 0; }
        #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div div:nth-child(3),
        #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 div div:nth-child(3) {
          float: left;
          width: 120px;
          height: 45px;
          line-height: 45px;
          border: 1px solid #c6e1ed;
          text-align: center;
          margin-top: 20px;
          margin-bottom: 0; }
          #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div div:nth-child(3) a,
          #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 div div:nth-child(3) a {
            background: transparent url("../img/product_arrow01.png") no-repeat scroll 93px center;
            display: block;
            width: 120px;
            height: 43px;
            font-size: 15px;
            color: #0c8dc4; }
            #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div div:nth-child(3) a:hover,
            #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 div div:nth-child(3) a:hover {
              text-decoration: none;
              width: 120px;
              height: 44px;
              background-color: #419dc4;
              background: #419dc4 url("../img/product_arrow01h.png") no-repeat scroll 93px center;
              color: #fff;
              opacity: 1; }
      #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div:nth-child(odd),
      #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 div:nth-child(odd) {
        margin-right: 0; }
      #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div:last-child,
      #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div:nth-last-of-type(2),
      #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 div:last-child,
      #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 div:nth-last-of-type(2) {
        margin-bottom: 0; }
    #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 h3 {
      border-bottom: 3px solid #c58756; }
    #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div div:nth-child(3) {
      border: 1px solid #eddbcc; }
      #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div div:nth-child(3) a {
        background: transparent url("../img/product_arrow02.png") no-repeat scroll 93px center;
        color: #bb6827; }
        #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox01 div div:nth-child(3) a:hover {
          background-color: #c58756;
          opacity: 1;
          background: #c58756 url("../img/product_arrow02h.png") no-repeat scroll 93px center; }
    #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox02 h3 {
      margin-top: 98px; }
    #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox03 div {
      text-align: center;
      margin-top: 100px;
      margin-bottom: 133px; }
      #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox03 div a {
        background: transparent url("../img/product_arrow03.png") no-repeat scroll 952px center;
        height: 105px;
        line-height: 105px;
        display: block;
        border: 1px solid #cae7b2;
        font-size: 22px;
        color: #4fb000; }
        #PageProducts.pageIndex #Container #Main #ContBox01 .contSubBox03 div a:hover {
          background-color: #4fb000;
          opacity: 1;
          color: #fff;
          text-decoration: none; }

/*
製品紹介 共通定義
-------------------------------------*/
#PageProducts.pageDiesel #Container #Main h3,
#PageProducts.pageInertgas #Container #Main h3,
#PageProducts.pageBoiler #Container #Main h3,
#PageProducts.pagePaint #Container #Main h3,
#PageProducts.pageCombustion #Container #Main h3,
#PageProducts.pageExhaustgas #Container #Main h3,
#PageProducts.pageParticular #Container #Main h3,
#PageProducts.pageFan #Container #Main h3 {
  padding-top: 14px;
  height: 89px;
  line-height: 20px;
  letter-spacing: 3px; }
  #PageProducts.pageDiesel #Container #Main h3 span,
  #PageProducts.pageInertgas #Container #Main h3 span,
  #PageProducts.pageBoiler #Container #Main h3 span,
  #PageProducts.pagePaint #Container #Main h3 span,
  #PageProducts.pageCombustion #Container #Main h3 span,
  #PageProducts.pageExhaustgas #Container #Main h3 span,
  #PageProducts.pageParticular #Container #Main h3 span,
  #PageProducts.pageFan #Container #Main h3 span {
    letter-spacing: 1px; }

#PageProducts.pageDiesel #Container #Main div,
#PageProducts.pageInertgas #Container #Main div,
#PageProducts.pageBoiler #Container #Main div,
#PageProducts.pagePaint #Container #Main div,
#PageProducts.pageCombustion #Container #Main div,
#PageProducts.pageExhaustgas #Container #Main div,
#PageProducts.pageParticular #Container #Main div,
#PageProducts.pageFan #Container #Main div {
  padding-left: 270px; }
  #PageProducts.pageDiesel #Container #Main div p,
  #PageProducts.pageInertgas #Container #Main div p,
  #PageProducts.pageBoiler #Container #Main div p,
  #PageProducts.pagePaint #Container #Main div p,
  #PageProducts.pageCombustion #Container #Main div p,
  #PageProducts.pageExhaustgas #Container #Main div p,
  #PageProducts.pageParticular #Container #Main div p,
  #PageProducts.pageFan #Container #Main div p {
    margin-bottom: 13px;
    font-size: 16px;
    line-height: 27px; }
  #PageProducts.pageDiesel #Container #Main div p:last-child,
  #PageProducts.pageInertgas #Container #Main div p:last-child,
  #PageProducts.pageBoiler #Container #Main div p:last-child,
  #PageProducts.pagePaint #Container #Main div p:last-child,
  #PageProducts.pageCombustion #Container #Main div p:last-child,
  #PageProducts.pageExhaustgas #Container #Main div p:last-child,
  #PageProducts.pageParticular #Container #Main div p:last-child,
  #PageProducts.pageFan #Container #Main div p:last-child {
    margin-bottom: 0; }

/*
ディーゼル機関用補助ブロワ
-------------------------------------*/
#PageProducts.pageDiesel #Main div {
  background: url(../img/diesel_img02.jpg) no-repeat left top;
  background-size: 240px 148px; }

/*
イナートガス装置用ブロワ
-------------------------------------*/
#PageProducts.pageInertgas #Container {
  margin-bottom: 118px; }
  #PageProducts.pageInertgas #Container #Main div {
    background: url(../img/inato_img02.jpg) no-repeat left top;
    background-size: 240px 148px; }

/*
ボイラー用強圧ファン
-------------------------------------*/
#PageProducts.pageBoiler #Container {
  margin-bottom: 138px; }
  #PageProducts.pageBoiler #Container #Main div {
    background: url(../img/boiler_img02.jpg) no-repeat left top;
    background-size: 240px 148px; }

/*
燃焼空気ファン
-------------------------------------*/
#PageProducts.pageCombustion #Container {
  margin-bottom: 96px; }
  #PageProducts.pageCombustion #Container #Main div {
    background: url(../img/nensyou_img02.jpg) no-repeat left top;
    background-size: 240px 148px; }

/*
排ガス誘引ファン
-------------------------------------*/
#PageProducts.pageExhaustgas #Container {
  margin-bottom: 166px; }
  #PageProducts.pageExhaustgas #Container #Main div {
    background: url(../img/haigus_img02.jpg) no-repeat left top;
    background-size: 240px 148px; }

/*
特殊ファン
-------------------------------------*/
#PageProducts.pageParticular #Container {
  margin-bottom: 170px; }
  #PageProducts.pageParticular #Container #Main div {
    background: url(../img/tokusyu_img02.jpg) no-repeat left top;
    background-size: 240px 148px; }

/*
塗装換気ファン
-------------------------------------*/
#PageProducts.pagePaint #Container {
  margin-bottom: 170px; }
  #PageProducts.pagePaint #Container #Main div {
    background: url(../img/tosou_img02.jpg) no-repeat left top;
    background-size: 240px 296px;
    height: 296px; }

/*
ファンタイプ別
-------------------------------------*/
#PageProducts.pageFan #Container {
  margin-bottom: 158px;
  width: 100%; }
  #PageProducts.pageFan #Container #Main .contSubBox01 {
    padding-bottom: 80px; }
    #PageProducts.pageFan #Container #Main .contSubBox01 > div {
      overflow: hidden; }
  #PageProducts.pageFan #Container #Main .contSubBox02 {
    padding-bottom: 100px; }
    #PageProducts.pageFan #Container #Main .contSubBox02 div.clear + div p:first-child {
      margin-top: 23px; }
  #PageProducts.pageFan #Container #Main h3 {
    height: 60px; }
  #PageProducts.pageFan #Container #Main h4:first-child {
    margin-top: 0; }
  #PageProducts.pageFan #Container #Main div {
    padding-left: 0; }
    #PageProducts.pageFan #Container #Main div.clear {
      overflow: hidden; }
    #PageProducts.pageFan #Container #Main div.left {
      float: left;
      width: 390px; }
    #PageProducts.pageFan #Container #Main div.right {
      float: right;
      width: 280px; }
  #PageProducts.pageFan #Container #Main p.note {
    margin-top: 9px;
    font-size: 14px;
    color: #888888;
    text-align: right; }

/*
設計と開発 製造工程 p 共通定義
-------------------------------------*/
#PageDevelopment.pageIndex #Main p,
#PageProcess.pageIndex #Main p,
#PageMaintenance.pageIndex #Main p {
  font-size: 16px;
  line-height: 28px;
  margin-top: 14px; }

/*
設計と開発 製造工程 h3 共通定義
-------------------------------------*/
#PageDevelopment.pageIndex #Main h3,
#PageProcess.pageIndex #Main h3,
#PageMaintenance.pageIndex #Main h3 {
  padding-bottom: 16px; }

/*
ファンタイプ別 製造工程 h4 共通定義
-------------------------------------*/
#PageProducts.pageFan #Main h4,
#PageProcess.pageIndex #Main h4 {
  font-size: 22px;
  font-weight: bold;
  color: #009944;
  margin-top: 60px;
  padding-left: 20px;
  border: 1px solid #ccedda;
  background-color: #f2fef7;
  height: 60px;
  line-height: 60px; }

/*
設計と開発
-------------------------------------*/
#PageDevelopment.pageIndex #Container {
  width: 100%;
  margin-bottom: 125px; }
  #PageDevelopment.pageIndex #Container #Main {
    float: none;
    width: 100%; }
    #PageDevelopment.pageIndex #Container #Main #ContBox01 {
      width: 100%; }
    #PageDevelopment.pageIndex #Container #Main h4 {
      font-size: 20px;
      font-weight: bold;
      color: #009944;
      line-height: 32px;
      margin-bottom: 22px; }
    #PageDevelopment.pageIndex #Container #Main .contSubBox01 {
      background: url(../img/develop_img01.jpg) no-repeat left top;
      background-size: 500px 309px;
      height: 309px; }
      #PageDevelopment.pageIndex #Container #Main .contSubBox01 h4,
      #PageDevelopment.pageIndex #Container #Main .contSubBox01 p {
        width: 470px;
        margin-left: 530px; }
      #PageDevelopment.pageIndex #Container #Main .contSubBox01 p {
        font-weight: bold; }
    #PageDevelopment.pageIndex #Container #Main .contSubBox02 {
      margin-top: 46px; }

/*
製造工程
-------------------------------------*/
#PageProcess.pageIndex #Container {
  width: 100%;
  margin-bottom: 150px; }
  #PageProcess.pageIndex #Container #Main {
    float: none;
    width: 100%; }
    #PageProcess.pageIndex #Container #Main #ContBox01 {
      width: 100%; }
    #PageProcess.pageIndex #Container #Main p {
      width: 473px;
      margin-right: 30px; }
    #PageProcess.pageIndex #Container #Main p:first-child {
      margin-top: 0; }
    #PageProcess.pageIndex #Container #Main div {
      padding: 26px 0 60px 30px;
      margin-left: 30px;
      border-left: 7px solid #009944; }
    #PageProcess.pageIndex #Container #Main h4 {
      margin-top: 0;
      margin-bottom: 0; }
    #PageProcess.pageIndex #Container #Main .contSubBox01 div,
    #PageProcess.pageIndex #Container #Main .contSubBox02 div,
    #PageProcess.pageIndex #Container #Main .contSubBox03 div,
    #PageProcess.pageIndex #Container #Main .contSubBox04 div {
      height: 350px; }
    #PageProcess.pageIndex #Container #Main .contSubBox01 div {
      background: url(../img/koutei_img01.jpg) no-repeat right 26px;
      background-size: 430px 266px;
      height: 350px; }
    #PageProcess.pageIndex #Container #Main .contSubBox02 div {
      background: url(../img/koutei_img02.jpg) no-repeat right 26px;
      background-size: 430px 266px; }
    #PageProcess.pageIndex #Container #Main .contSubBox03 div {
      background: url(../img/koutei_img03.jpg) no-repeat right 26px;
      background-size: 430px 266px; }
    #PageProcess.pageIndex #Container #Main .contSubBox04 div {
      background: url(../img/koutei_img04.jpg) no-repeat right 26px;
      background-size: 430px 266px;
      height: 293px;
      border: none; }

/*
メンテナンス
-------------------------------------*/
#PageMaintenance.pageIndex #Container {
  width: 100%;
  margin-bottom: 240px; }
  #PageMaintenance.pageIndex #Container #Main {
    float: none;
    width: 100%; }
  #PageMaintenance.pageIndex #Container .contSubBox01 {
    height: 370px;
    background: url(../img/maintenance_img01.jpg) no-repeat left 132px;
    background-size: 1000px 240px; }
    #PageMaintenance.pageIndex #Container .contSubBox01 p {
      margin-top: 0; }
    #PageMaintenance.pageIndex #Container .contSubBox01 ul {
      margin-top: 8px; }
      #PageMaintenance.pageIndex #Container .contSubBox01 ul li {
        display: table-cell;
        font-size: 11px;
        height: 58px;
        vertical-align: middle;
        white-space: nowrap; }
        #PageMaintenance.pageIndex #Container .contSubBox01 ul li.tel {
          width: 200px; }
          #PageMaintenance.pageIndex #Container .contSubBox01 ul li.tel span:nth-child(1) {
            padding-left: 34px;
            background: url("../img/ico_phone.png") no-repeat scroll left center;
            background-size: 20px 20px;
            font-size: 26px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 700;
            letter-spacing: 1px; }
          #PageMaintenance.pageIndex #Container .contSubBox01 ul li.tel span:nth-child(2) {
            display: block;
            padding-top: 5px;
            color: #888;
            font-size: 11px;
            text-align: center;
            letter-spacing: 1px; }
        #PageMaintenance.pageIndex #Container .contSubBox01 ul li:nth-child(2) a {
          margin-left: 36px;
          width: 180px;
          height: 33px;
          line-height: 33px;
          text-align: center;
          display: block;
          background-color: #01a54f;
          color: #fff;
          font-size: 13px;
          text-decoration: none;
          line-height: 33px; }
  #PageMaintenance.pageIndex #Container .contSubBox02 {
    height: 577px;
    margin-top: 100px; }
    #PageMaintenance.pageIndex #Container .contSubBox02 h3 {
      border-bottom: 3px solid #c58756; }
    #PageMaintenance.pageIndex #Container .contSubBox02 ul {
      margin-top: 40px; }
      #PageMaintenance.pageIndex #Container .contSubBox02 ul li {
        font-size: 20px;
        font-weight: bold;
        display: inline-block;
        width: 300px;
        height: 405px;
        margin-right: 46px; }
        #PageMaintenance.pageIndex #Container .contSubBox02 ul li:nth-child(1) {
          background: url(../img/maintenance_img03.jpg) no-repeat left 33px;
          background-size: 300px 372px; }
        #PageMaintenance.pageIndex #Container .contSubBox02 ul li:nth-child(2) {
          background: url(../img/maintenance_img04.jpg) no-repeat left 33px;
          background-size: 300px 372px; }
        #PageMaintenance.pageIndex #Container .contSubBox02 ul li:nth-child(3) {
          margin-right: 0;
          background: url(../img/maintenance_img05.jpg) no-repeat left 33px;
          background-size: 300px 186px; }
  #PageMaintenance.pageIndex #Container .contSubBox03 {
    height: 438px;
    margin-top: 100px;
    background: url(../img/maintenance_img02.jpg) no-repeat left 194px;
    background-size: 1000px 240px; }
    #PageMaintenance.pageIndex #Container .contSubBox03 h3 {
      border-bottom: 3px solid #419dc4; }

/*
お問い合わせ
-------------------------------------*/
#PageContact.pageIndex #Container {
  width: 100%; }
  #PageContact.pageIndex #Container #Main #ContBox01 {
    width: 100%; }

/*
お問い合わせ
-------------------------------------*/
#PageContact.pageConfirm #Container {
  width: 100%; }
  #PageContact.pageConfirm #Container #Main #ContBox01 {
    width: 100%; }

/*
お問い合わせ
-------------------------------------*/
#PageContact.pageThanks #Container {
  width: 100%; }
  #PageContact.pageThanks #Container #Main #ContBox01 {
    width: 100%; }
